<template>
	<div class="page">
		<Tables ref="xTable" :tableName="tableName" :tableData="List" :totalPage="totalPage" :isLoading="isLoading"
			:searchFormData="searchFormData" :showRefresh="true" :showSearch="true"
			:showAdd="$hasAccess('market.chargecoupon/add')" addName="新增" @getList="getList" @clearSearch="clearSearch"
			@addItemInTable="addOne">

			<!--搜索自定义内容-->
			<el-form-item slot="search-item">
				<el-input v-model="searchFormData.name" size="small" clearable placeholder="按名称查询">
				</el-input>
			</el-form-item>
			<!--表格内容-->
			<vxe-table-column slot="table-item" field="id" title="活动ID" align="center" min-width="100px" />
			<vxe-table-column slot="table-item" field="name" title="名称" align="center" min-width="100px" />
			<vxe-table-column slot="table-item" field="group_id" title="场站ID" align="center" min-width="50px" />
			<vxe-table-column slot="table-item" field="group_name" title="场站名称" align="center" min-width="50px" />
			<vxe-table-column slot="table-item" field="ele_price" title="电费" align="center" min-width="80px" />
			<vxe-table-column slot="table-item" field="service_price" title="服务费" align="center" min-width="80px" />
			<vxe-table-column slot="table-item" field="btime" title="开始时间" align="center" min-width="80px" />
			<vxe-table-column slot="table-item" field="etime" title="结束时间" align="center" min-width="80px" />
			<vxe-table-column slot="table-item" field="status" title="状态" align="center" min-width="50px">
				<template v-slot="{ row }">
					<span>{{row.status==1?'有效':'无效'}}</span>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" title="操作" align="center" width="200px">
				<template v-slot="{ row }">
					<el-button size="small" plain @click="editOne(row)" v-if="$hasAccess('market.chargecoupon/edit')">编辑
					</el-button>
					<el-button size="small" plain @click="deleteOne(row)">删除</el-button>
				</template>
			</vxe-table-column>
		</Tables>

		<!-- 新增/编辑 -->
		<el-dialog :title="(editDialogFormData.id>0?'编辑':'新增')+'特惠站点'" :visible.sync="editDialogShow" :close-on-click-modal="false"
			:close-on-press-escape="false" width="700px">
			<el-form :model="editDialogFormData" :rules="rules" ref="editForm" label-width="100px" class="form">
				<el-form-item label="名称" prop="name">
					<el-input v-model="editDialogFormData.name" style="width: 80%;"></el-input>
				</el-form-item>
				<el-form-item label="特惠图标" prop="attach_id">
					<upload :fileList="imageList" @getFileInfo="getFileInfo">
					</upload>
				</el-form-item>
				<el-form-item label="区域" prop="citys">
					<el-select v-model="citys" placeholder="请选择" multiple style="width:100%" @change="changeCity">
						<el-option label="全部" value="10"></el-option>
						<el-option v-for="(item,k) in $userArea" :key="k" :label="item" :value="item"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="场站" prop="group_id">
					<el-select v-model="editDialogFormData.group_id" placeholder="请选择场站" style="width:100%">
						<el-option v-for="(item,k) in groupList" :key="k" :label="item.group_name" :value="item.group_id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="电价" prop="ele_price">
					<el-input-number type="number" :min="0" :max="100" :step="0.1" :precision="4"
						v-model="editDialogFormData.ele_price" style="width: 168px;" class="input" size="small">
					</el-input-number>
				</el-form-item>
				<el-form-item label="服务费" prop="service_price">
					<el-input-number type="number" :min="0" :max="100" :step="0.1" :precision="4"
						v-model="editDialogFormData.service_price" style="width: 168px;" class="input" size="small">
					</el-input-number>
				</el-form-item>	
				<el-form-item label="开始时间" prop="btime">
					<el-date-picker class="from-item-width" unlink-panels v-model="editDialogFormData.btime"
						type="date" value-format="yyyy-MM-dd" placeholder="开启时间"></el-date-picker>
				</el-form-item>
				<el-form-item label="结束时间" prop="etime">
					<el-date-picker class="from-item-width" unlink-panels v-model="editDialogFormData.etime"
						type="date" value-format="yyyy-MM-dd" placeholder="结束时间"></el-date-picker>
				</el-form-item>
				<el-form-item label="是否有效" prop="status">
					<el-switch v-model="editDialogFormData.status" active-value="1" inactive-value="0" clearable />
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" size="small" plain icon="el-icon-check" @click="saveEdit()">保 存
				</el-button>
				<el-button size="small" plain icon="el-icon-close" @click="closeDialog">取 消
				</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import Tables from '@/components/tables'
	import upload from '@/components/upload'
	export default {
		name: 'market-group-list',
		components: {
			Tables,upload
		},
		data() {
			return {
				// 表格信息
				tableName: '特惠场站',
				isLoading: false,
				totalPage: 0,
				searchFormData: {},
				List: [],
				citys: [],
				groupList: [],
				imageList: [],
				rules: {
					name: [{
						required: true,
						message: '请输入名称',
						trigger: 'change'
					}],
					ele_price: [{
						required: true,
						message: '请输入电费',
						trigger: 'change'
					}],
					service_price: [{
						required: true,
						message: '请输入电费',
						trigger: 'change'
					}],
					attach_id: [{
						required: true,
						message: '请上传图标',
						trigger: 'change'
					}],
					group_id: [{
						required: true,
						message: '请输入条件限制',
						trigger: 'change'
					}],
					btime: [{
						required: true,
						message: '请输入开始时间',
						trigger: 'change'
					}],
					etime: [{
						required: true,
						message: '请输入结束时间',
						trigger: 'change'
					}],
				},
				// 编辑
				editDialogShow: false,
				editDialogFormData: {cfg:{}},
			}
		},
		computed: {
		},
		mounted() {
			this.getList();
		},
		activated() {},
		methods: {
			// 表格列表
			async getList(currentPage, pageSize) {
				let params = {
					token: this.$store.state.user.token,
					page: currentPage,
					size: pageSize
				}
				params = Object.assign(params, this.searchFormData);
				this.isLoading = true
				const res = await this.$api.Market.Group.list(params);
				this.tableData = res.data
				this.totalPage = res.total
				this.isLoading = false
			},
			// 获取标签内容
			async getList() {
				let params = {
					token: this.$store.state.user.token,
					page: 1,
					size: 100
				}
				params = Object.assign(params, this.searchFormData);
				const res = await this.$api.Market.Group.list(params, {})
				this.List = res.data;
			},
			// 重置搜索
			clearSearch() {
				this.searchFormData = {}
			},
			// 重置form
			resetFormData() {
				this.editDialogFormData = {}
				if (this.$refs.editForm) {
					this.$refs.editForm.resetFields()
				}
			},
			// 新增
			addOne() {
				this.resetFormData();
				this.imageList = [];
				this.editDialogShow = true;
			},
			getFileInfo(fileInfo) { // 附件
				this.editDialogFormData.attach_id = fileInfo;
			},
			// 编辑
			async editOne(row) {
				this.resetFormData();
				row.status = row.status.toString();
				this.editDialogFormData = Object.assign({}, row);
				delete this.editDialogFormData._XID;
				this.editDialogShow = true;
				this.imageList = [{url:this.editDialogFormData.url}];
				this.getGroup();
			},
			// 删除
			deleteOne(row) {
				this.$confirm('确定要删除该记录吗', '提示', {
					cancelButtonClass: 'btn-custom-cancel',
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					const params = {
						token: this.$store.state.user.token,
						id: row.id
					}
					await this.$api.Market.Group.del(params)
					this.$notify({
						title: '成功',
						message: '删除成功',
						type: 'success'
					})
					this.$refs.xTable.refreshTable()
				})
			},
			// 关闭弹窗
			closeDialog() {
				this.editDialogShow = false;
				this.$refs.xTable.refreshTable();
			},
			// 编辑保存
			saveEdit() {
				this.$refs.editForm.validate(async valid => {
					//console.log('valid', valid)
					if (valid) {
						delete this.editDialogFormData.group_name;
						delete this.editDialogFormData.url;
						const params = {
							token: this.$store.state.user.token,
							form: JSON.stringify(this.editDialogFormData)
						}
						if(this.editDialogFormData.id > 0){
							await this.$api.Market.Group.edit(params);
						}else{
							await this.$api.Market.Group.add(params);
						}
						this.$notify({
							title: '成功',
							message: '操作成功',
							type: 'success'
						})
						this.closeDialog();
					}
				})
			},
			changeCity(e){ //修改城市
				this.getGroup();
			},
			getGroup(){ //获取场站
				const params = {
					token: this.$store.state.user.token,
					page: 1,
					size: 100,
					city: this.citys
				}
				this.$api.Device.GetGroupMinList(params).then((res)=>{
					this.groupList = res;
				});
			},
		}
	}
</script>

<style lang="scss" scoped>
</style>
